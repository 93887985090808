import React from "react";
import Layout from "../../components/layout";
import ProjectComponent from "../../components/projects/projectComponent";

import tom_arrow from "../../images/projects/tom/icons/tom-arrow.svg";
import tom_cross from "../../images/projects/tom/icons/tom-cross.svg";
import tom_atm from "../../images/projects/tom/icons/tom-atm.svg";
import tom_car from "../../images/projects/tom/icons/tom-car.svg";
import tom_dining from "../../images/projects/tom/icons/tom-dining.svg";
import tom_discount from "../../images/projects/tom/icons/tom-discount.svg";

import {graphql, useStaticQuery} from "gatsby";

const ProjectTom = () => {
    const data = useStaticQuery(graphql`
        query {
            banner: file(relativePath: {eq: "projects/tom/tom-banner.jpg"}) {
                childImageSharp {
                    gatsbyImageData
                }
            }
            picture: file(relativePath: {eq: "projects/tom/tom-picture.jpg"}) {
                childImageSharp {
                    gatsbyImageData
                }
            }
        }
    `);

    const projectData = {
        project: "tom",
        website: "https://arkhangelskoyeoutlet.com/",
        banner: data.banner.childImageSharp.gatsbyImageData,
        stages: {
            about: true,
            design: true,
            tech: true,
            result: true,
        },
        colors: ["#fef4f3", "#232427", "#f3907f"],
        font: "",
        icons: [tom_arrow, tom_cross, tom_atm, tom_car, tom_dining, tom_discount],
        picture: data.picture.childImageSharp.gatsbyImageData,
        googlePlay: "",
        appStore: "",
    };

    return (
        <Layout>
            <ProjectComponent projectData={projectData} />
        </Layout>
    );
};

export default ProjectTom;
